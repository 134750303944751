import axios from "axios";
import { env } from "../config";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as Actions from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";

export interface EventData {
  id: number;
  attributes: Attributes;
}

export interface Attributes {
  title: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  url: any;
  description: string;
  eventDates: string;
  date: string;
  location: string;
  booth: string;
  meetLink: string;
  innerPageTitle: string;
  innerPageDescription: string;
  gallery: { data: GalleryData[] };
}

export interface GalleryData {
  id: number;
  attributes: ImageAttributes;
}

export interface ImageAttributes {
  name: string;
  alternativeText: any;
  caption: any;
  width: number;
  height: number;
  formats: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: any;
  provider: string;
  provider_metadata: any;
  createdAt: string;
  updatedAt: string;
}

export interface Formats {
  thumbnail: Thumbnail;
  small: Small;
  medium: Medium;
  large: Large;
}

export interface Thumbnail {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface Small {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface Medium {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface Large {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

const EventPage = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const EventData: EventData[] = useSelector(
    (state: any) => state?.Page?.EventPageData
  );
  const getEventData = async () => {
    setloading(true);
    await axios
      .get(`${env}/api/events?populate=deep`)
      .then(function (response) {
        dispatch(Actions.EventPageAction(response?.data?.data));
        findUpcomingEvent(response?.data?.data);
      })
      .finally(() => {
        setloading(false);
      });
  };
  const [upcomingEvent, setUpcomingEvent] = useState<number>(0);

  useEffect(() => {
    if (EventData?.length === 0) {
      getEventData();
    }
    window.scrollTo({
      top: 0,
      left: 1,
      behavior: "smooth",
    });
    findUpcomingEvent(EventData);
  }, []);

  const navigate = useNavigate();

  const navigateTo = (title: string) => {
    navigate(`/events/${title?.toLowerCase().replace(/\s+/g, "-")}`);
  };

  console.log("ajndkajdsn", EventData);

  function sortDates(dateArray: EventData[], order = "asc") {
    return dateArray.sort((a, b) => {
      const dateA = new Date(a?.attributes?.date).getTime();
      const dateB = new Date(b?.attributes?.date).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
  }

  function findUpcomingEvent(dateArray: EventData[]) {
    const currentDate = new Date()?.toISOString()?.split("T")[0];
    const reduceDateArray = dateArray
      ?.map((event) => {
        const date1 = new Date(currentDate);
        const date2 = new Date(event?.attributes?.date);

        // Calculate the time difference in milliseconds
        const timeDiff = date2.getTime() - date1.getTime();

        // Convert milliseconds to days
        const daysDiff = Math.round(timeDiff / (1000 * 60 * 60 * 24));

        console.log(`Difference in days: ${daysDiff}`);
        return { ...event, diff: daysDiff };
      })
      ?.filter((a) => a?.diff > 0 || a?.diff === 0)
      ?.sort((a, b) => a?.diff - b?.diff);
    setUpcomingEvent(reduceDateArray[0]?.id);
  }

  return loading ? (
    <Loader />
  ) : (
    <div className="w-full">
      <EventPageBanner />
      <div className="grid  md:grid-cols-[repeat(auto-fit,minmax(375px,1fr))] gap-7 max-w-screen-2xl mx-auto  px-5 py-7 lg:px-10 xl:px-20 lg:py-14">
        {sortDates(EventData, "dsc")?.map((event) => {
          return (
            <article className="rounded-lg border relative border-gray-100 bg-white font-custom  p-4 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] transition hover:shadow-lg sm:p-6">
              <div className="h-[250px] overflow-hidden shrink-0 rounded-md relative">
                {event?.id === upcomingEvent && (
                  <div className="absolute top-2 left-2 z-10 bg-white text-sm font-medium px-2.5 py-1 rounded-full text-green-600 border border-green-600 shadow">
                    Upcoming
                  </div>
                )}
                <img
                  width={event?.attributes?.gallery?.data[0]?.attributes?.width}
                  height={
                    event?.attributes?.gallery?.data[0]?.attributes?.height
                  }
                  className="h-full w-full object-fill"
                  src={`${env}${event?.attributes?.gallery?.data[0]?.attributes?.formats?.small?.url}`}
                  alt={event?.attributes?.gallery?.data[0]?.attributes?.name}
                />
              </div>

              <div>
                <div className=" flex  items-center mt-3 gap-2 py-2">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.31699 10.0167L10.017 9.31666L7.50033 6.79999V3.66666H6.50033V7.19999L9.31699 10.0167ZM7.00033 13.3333C6.12255 13.3333 5.29766 13.1667 4.52566 12.8333C3.75321 12.5 3.08366 12.05 2.51699 11.4833C1.95033 10.9167 1.50033 10.2471 1.16699 9.47466C0.833659 8.70266 0.666992 7.87777 0.666992 6.99999C0.666992 6.12221 0.833659 5.2971 1.16699 4.52466C1.50033 3.75266 1.95033 3.08332 2.51699 2.51666C3.08366 1.94999 3.75321 1.49999 4.52566 1.16666C5.29766 0.833323 6.12255 0.666656 7.00033 0.666656C7.8781 0.666656 8.70321 0.833323 9.47566 1.16666C10.2477 1.49999 10.917 1.94999 11.4837 2.51666C12.0503 3.08332 12.5003 3.75266 12.8337 4.52466C13.167 5.2971 13.3337 6.12221 13.3337 6.99999C13.3337 7.87777 13.167 8.70266 12.8337 9.47466C12.5003 10.2471 12.0503 10.9167 11.4837 11.4833C10.917 12.05 10.2477 12.5 9.47566 12.8333C8.70321 13.1667 7.8781 13.3333 7.00033 13.3333ZM7.00033 12.3333C8.4781 12.3333 9.73655 11.814 10.7757 10.7753C11.8143 9.73621 12.3337 8.47777 12.3337 6.99999C12.3337 5.52221 11.8143 4.26377 10.7757 3.22466C9.73655 2.18599 8.4781 1.66666 7.00033 1.66666C5.52255 1.66666 4.26433 2.18599 3.22566 3.22466C2.18655 4.26377 1.66699 5.52221 1.66699 6.99999C1.66699 8.47777 2.18655 9.73621 3.22566 10.7753C4.26433 11.814 5.52255 12.3333 7.00033 12.3333Z"
                      fill="#8A8A8A"
                    />
                  </svg>
                  <div className="text-xs  font-custom text-[#8A8A8A]">
                    {event?.attributes?.date}
                  </div>
                </div>

                <h3 className="mt-0.5 text-xl font-medium text-black-75">
                  {event?.attributes?.title}
                </h3>

                <p className="mt-2 cardClampCareerCard text-sm/relaxed text-gray-500">
                  {event?.attributes?.description}
                </p>

                <div
                  role="button"
                  onClick={() => navigateTo(event?.attributes?.url)}
                  className="group mt-4 inline-flex items-center gap-1 group text-sm font-medium text-[#f7941e]"
                >
                  Find out more
                  <span
                    aria-hidden="true"
                    className="block transition-all group-hover:translate-x-1 rtl:rotate-180"
                  >
                    &rarr;
                  </span>
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default EventPage;

const EventPageBanner = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${env}/uploads/1613_4db4f759d0.webp)`,
      }}
      className={` bg-no-repeat bg-cover text-black-75 bg-opacity-60  h-96 md:h-[400px] lg:h-[500px]   px-5 py-7 lg:px-10 xl:px-20 lg:py-14 bg-center flex flex-col  justify-center items-center gap-5`}
    >
      <h4 className="font-custom font-bold text-white text-3xl sm:text-4xl md:text-5xl xl:text-6xl">
        Events
      </h4>
      <p className="text-sm sm:text-base md:text-lg lg:text-xl  text-white  mt-5 font-custom xl:w-[50%] text-center">
        We're coming to a city near you! Check our event schedule.
      </p>
    </div>
  );
};
