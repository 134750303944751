import { EventData } from "../Pages/EventPage";
import {
  EXPERTISEPAGE,
  HOMEPAGE,
  IOTPAGE,
  IOTPLATFORMPAGE,
  CART,
  TELECOMPAGE,
  SERVICESPAGE,
  BLOGPAGE,
  CAREERPAGE,
  SMARTCITYPAGE,
  CAREERINDIVIDUAL,
  JOBSELECTION,
  NSEPPAGE,
  OFFICELIST,
  CONTACTPAGE,
  AGROTECHPAGE,
  BLOGCONTENT,
  IOTDEVELOPMENTPAGE,
  FARMTOPLATEPAGE,
  BARGEPAGE,
  PRODUCTDEVELOPMENTPAGE,
  APPLICATIONDEVELOPMENTPAGE,
  ECOMMERCEDEVELOPMENTPAGE,
  CLOUDSERVICESPAGE,
  QUALITYASSURANCEPAGE,
  TELECOMSYSTEMSPAGE,
  ITCONSULTINGPAGE,
  DIGITALTRANSFORMATIONPAGE,
  ITCONSULTINGHOMEPAGE,
  CLOUDSOLUTIONSPAGE,
  DEVOPSANDINTEGRATIONPAGE,
  ETHNICDAYPAGE,
  COMPANYPAGE,
  FINANCEPAGE,
  DATAANALYTICSPAGE,
  PRODUCTDATA,
  WEBSITEDEVELOPMENTLANDINGPAGE,
  MOBILEAPPDEVELOPMENTLANDINGPAGE,
  WIKIPEEDIKAPAGE,
  WIKIPEEDIKABLOGCONTENT,
  NCSPAGE,
  NCSINDIVIDUALPAGES,
  COMMNUNITYCOMMERCEPAGE,
  OFFSHOREWINDPAGE,
  WHITEPAPERPAGE,
  WHITEPAPERCONTENT,
  EVENTPAGECONTENT,
} from "./actions";

const initialState = {
  HomePageContent: {},
  ExpertisePageContent: {},
  TelecomPageContent: {},
  IotPageContent: {},
  IotPlatformPageContent: {},
  ServicesPageContent: {},
  BlogPageContent: {},
  CareerPageContent: {},
  SmartCityPageContent: {},
  CareerIndividualarray: [],
  SelectedJob: {},
  NsepPageContent: {},
  OfficeListContent: [],
  ContactPageContent: {},
  AgrotechPageContent: {},
  BlogContent: [],
  IotDevelopmentPageContent: {},
  FarmToPlatePageContent: {},
  BargePageContent: {},
  ProductDevelopmentPageContent: {},
  ApplicationDevelopmentPageContent: {},
  EcommerceDevelopmentPageContent: {},
  CloudServicesPageContent: {},
  QualityAssurancePageContent: {},
  TelecomSystemsPageContent: {},
  ItConsultingPageContent: {},
  DigitalTransfromationPageContent: {},
  ItConsultingHomePageContent: {},
  CloudSolutionsPageContent: {},
  DevopsandIntegrationPageContent: {},
  EthnicDayPageContent: {},
  CompanyPageContent: {},
  FinancePageContent: {},
  DataAnalyticsPageContent: {},
  ProductData: [],
  WebsiteDevelopmentLandingContent: {},
  MobileAppDevelopmentLandingContent: {},
  WikipeedikaPageContent: {},
  WikipeedikaBlogContent: [],
  NcsPageContent: {},
  NcsIndividualPageContent: [],
  CommunityCommercePageContent: {},
  OffShoreWindPageContent: {},
  WhitePaperPageContent: {},
  WhitePaperContent: [],
  Cart: [] as { price: string; quantity: number; name: string }[],
  EventPageData: [] as EventData[],
};

export function PageReducer(state = initialState, action: any) {
  switch (action.type) {
    case HOMEPAGE: {
      return { ...state, HomePageContent: action?.payload };
    }

    case EXPERTISEPAGE: {
      return { ...state, ExpertisePageContent: action?.payload };
    }

    case TELECOMPAGE: {
      return { ...state, TelecomPageContent: action?.payload };
    }

    case IOTPAGE: {
      return { ...state, IotPageContent: action?.payload };
    }
    case IOTPLATFORMPAGE: {
      return { ...state, IotPlatformPageContent: action?.payload };
    }
    case SERVICESPAGE: {
      return { ...state, ServicesPageContent: action?.payload };
    }
    case BLOGPAGE: {
      return { ...state, BlogPageContent: action?.payload };
    }
    case CAREERPAGE: {
      return { ...state, CareerPageContent: action?.payload };
    }
    case SMARTCITYPAGE: {
      return { ...state, SmartCityPageContent: action?.payload };
    }
    case CAREERINDIVIDUAL: {
      return { ...state, CareerIndividualarray: action?.payload };
    }
    case JOBSELECTION: {
      return { ...state, SelectedJob: action?.payload };
    }
    case NSEPPAGE: {
      return { ...state, NsepPageContent: action?.payload };
    }
    case OFFICELIST: {
      return { ...state, OfficeListContent: action?.payload };
    }
    case CONTACTPAGE: {
      return { ...state, ContactPageContent: action?.payload };
    }
    case AGROTECHPAGE: {
      return { ...state, AgrotechPageContent: action?.payload };
    }
    case BLOGCONTENT: {
      return { ...state, BlogContent: action?.payload };
    }
    case IOTDEVELOPMENTPAGE: {
      return { ...state, IotDevelopmentPageContent: action?.payload };
    }
    case FARMTOPLATEPAGE: {
      return { ...state, FarmToPlatePageContent: action?.payload };
    }
    case BARGEPAGE: {
      return { ...state, BargePageContent: action?.payload };
    }
    case PRODUCTDEVELOPMENTPAGE: {
      return { ...state, ProductDevelopmentPageContent: action?.payload };
    }
    case APPLICATIONDEVELOPMENTPAGE: {
      return { ...state, ApplicationDevelopmentPageContent: action?.payload };
    }
    case ECOMMERCEDEVELOPMENTPAGE: {
      return { ...state, EcommerceDevelopmentPageContent: action?.payload };
    }
    case CLOUDSERVICESPAGE: {
      return { ...state, CloudServicesPageContent: action?.payload };
    }
    case QUALITYASSURANCEPAGE: {
      return { ...state, QualityAssurancePageContent: action?.payload };
    }
    case TELECOMSYSTEMSPAGE: {
      return { ...state, TelecomSystemsPageContent: action?.payload };
    }
    case ITCONSULTINGPAGE: {
      return { ...state, ItConsultingPageContent: action?.payload };
    }
    case DIGITALTRANSFORMATIONPAGE: {
      return { ...state, DigitalTransfromationPageContent: action?.payload };
    }
    case ITCONSULTINGHOMEPAGE: {
      return { ...state, ItConsultingHomePageContent: action?.payload };
    }
    case CLOUDSOLUTIONSPAGE: {
      return { ...state, CloudSolutionsPageContent: action?.payload };
    }
    case DEVOPSANDINTEGRATIONPAGE: {
      return { ...state, DevopsandIntegrationPageContent: action?.payload };
    }
    case ETHNICDAYPAGE: {
      return { ...state, EthnicDayPageContent: action?.payload };
    }
    case COMPANYPAGE: {
      return { ...state, CompanyPageContent: action?.payload };
    }
    case FINANCEPAGE: {
      return { ...state, FinancePageContent: action?.payload };
    }
    case DATAANALYTICSPAGE: {
      return { ...state, DataAnalyticsPageContent: action?.payload };
    }
    case PRODUCTDATA: {
      return { ...state, ProductData: action?.payload };
    }
    case WEBSITEDEVELOPMENTLANDINGPAGE: {
      return { ...state, WebsiteDevelopmentLandingContent: action?.payload };
    }
    case MOBILEAPPDEVELOPMENTLANDINGPAGE: {
      return { ...state, MobileAppDevelopmentLandingContent: action?.payload };
    }
    case WIKIPEEDIKAPAGE: {
      return { ...state, WikipeedikaPageContent: action?.payload };
    }
    case WIKIPEEDIKABLOGCONTENT: {
      return { ...state, WikipeedikaBlogContent: action?.payload };
    }
    case NCSPAGE: {
      return { ...state, NcsPageContent: action?.payload };
    }
    case NCSINDIVIDUALPAGES: {
      return { ...state, NcsIndividualPageContent: action?.payload };
    }
    case COMMNUNITYCOMMERCEPAGE: {
      return { ...state, CommunityCommercePageContent: action?.payload };
    }
    case OFFSHOREWINDPAGE: {
      return { ...state, OffShoreWindPageContent: action?.payload };
    }
    case WHITEPAPERPAGE: {
      return { ...state, WhitePaperPageContent: action?.payload };
    }
    case WHITEPAPERCONTENT: {
      return { ...state, WhitePaperContent: action?.payload };
    }
    case CART: {
      let copyCart = [...state?.Cart];
      const indexOfItemInCart = [...state?.Cart]?.findIndex(
        (item) => item?.price === action?.payload?.price
      );
      if (action?.payload?.quantity === 0) {
        console.log(
          "action?.payload?.quantity",
          action?.payload?.quantity,
          [...state?.Cart]?.filter(
            (node) => node?.price === action?.payload?.price
          )
        );
        return {
          ...state,
          Cart: [...state?.Cart]?.filter(
            (node) => node?.price !== action?.payload?.price
          ),
        };
      } else if (
        [...state?.Cart]
          ?.map((item) => item?.price)
          .includes(action?.payload?.price)
      ) {
        copyCart[indexOfItemInCart] = { ...action?.payload };
        return { ...state, Cart: [...copyCart] };
      } else {
        return { ...state, Cart: [...state?.Cart, action.payload] };
      }
    }
    case EVENTPAGECONTENT: {
      return { ...state, EventPageData: action?.payload };
    }

    default:
      return state;
  }
}

// EventPageAction
